import React from "react";

function About() {
    return (
        <div>
Article
        </div>
    );
}

export default About;
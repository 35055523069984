import React from "react";


function About() {
    return (
        <div>
           
            <br/><hr/>
<p className="footer-text">PinnyDB is an unofficial fan site dedicated to Pinny Arcade pins. We are in no way affiliated with Penny Arcade. 
    All copyrighted material is the property of it's respective owners. The use of this material is intended for non-profit, entertainment-only purposes. 
    No copyright infringement is intended at all and items will be removed if the owners wish. You may contact us at pinnydatabase@gmail.com.</p>
        </div>
    );
}

export default About;
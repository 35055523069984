import React from "react";

function ArticleList() {
    return (
        <div>
List of articles.
        </div>
    );
}

export default ArticleList;